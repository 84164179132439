<!--  集团客房经营报表 -->
<template>
  <section class="cont roomOperationReport">
    <Breadcrumb :crumbs="crumbs"/>

    <el-row class="content-box">
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="left-btn">
            <label>营业时间：</label>
            <el-date-picker v-model="operatorDate" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="width-250" :picker-options="pickerOptions"></el-date-picker>
            <el-button class="bg-gradient m-left-40" type="primary" @click="getData"><span>搜索</span></el-button>
            <el-button class="bg-gradient" type="primary" @click="reset"><span>重置</span></el-button>
            <el-button class="bg-gradient" type="primary" @click="dataExport"><span>导出</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" v-loading="loading">
          <el-table-column type="index" width="60" label="序号"></el-table-column>
          <el-table-column label="时间" min-width="30">
            <template slot-scope="scope">
              <div>{{ scope.row.date | filterDate }}</div>
            </template>
          </el-table-column>
          <el-table-column label="集团销售额" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.totalMoney.toFixed(2)}}</div>
            </template>
          </el-table-column>
          <el-table-column label="集团实际收入" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.incomeMoney.toFixed(2)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="orderCount" label="集团订单数" min-width="30"></el-table-column>
          <el-table-column prop="roomNum" label="集团销售房间数" min-width="30"></el-table-column>
          <el-table-column prop="userNum" label="购买人数" min-width="30"></el-table-column>
          <el-table-column label="平均房价" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.averagePrice.toFixed(2)}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="30">
            <template slot-scope="scope">
              <el-button type="text" @click="handleClick(scope.row.date)">
                <el-link type="primary">查看详情</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import {mapState} from "vuex";
import { exportFile } from '@/common/js/common'
import { getGroupRoomSituation,exportGroupRoomSituation } from "@/api/GroupManagement/groupManagement";
export default {
  data(){
    return{
      crumbs: new Map([
        ['集团管理'], ['统计报表'], ['集团客房经营报表']
      ]),
      operatorDate:[],     // 营业时间
      tableData:[],        // 表格数据
      loading: true,       // 表格加载效果
      total: 0,            // 用户列表总条目数
      page: 1,             // 当前页 默认第一页
      limit:10,            // 每页显示数

      // 日历快捷选项配置
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        }
      },
    }
  },
  computed:{
    ...mapState(['hotelInfo']),
  },
  mounted(){
    this.getData();
  },
  methods:{
    // 请求表格数据
    getData(){
      if(!this.operatorDate) {
        this.operatorDate = [];
      }
      let data = {
        limit:this.limit,
        page:this.page,
        beginTime:this.operatorDate[0],
        endTime:this.operatorDate[1],
        storeId:this.hotelInfo.storeId         // 集团ID
      }
      getGroupRoomSituation(data).then(({ success, records, total }) => {
        if (success) {
          this.total = total;
          this.tableData = records;
          this.loading = false;
        }
      })
    },
    // 重置
    reset(){
      this.operatorDate = [];
      this.page = 1;
      this.limit = 10;
      this.getData();
    },
    // 导出
    dataExport(){
      if(!this.operatorDate) {
        this.operatorDate = [];
      }
      let data = {
        limit:this.limit,
        page:this.page,
        beginTime:this.operatorDate[0],
        endTime:this.operatorDate[1],
        storeId:this.hotelInfo.storeId         // 集团ID
      }
      if (this.total) {
        exportGroupRoomSituation(data).then(res=>{
          exportFile(res,"集团客房经营报表" );
        })
      } else {
        this.$message({showClose: true,message:"没有数据可以导出",type: 'error'});
      }
    },
    // 查看
    handleClick(date){
      this.$router.push({ name: 'storeOperatingReport', params: { date } });
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getData();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getData();
    },
  },
  filters:{
    filterDate(val){
      let arr = val.split('-');
      return `${arr[0]}年${arr[1]}月${arr[2]}日`
    }
  }
}
</script>